// DO NOT EDIT THIS FILE DIRECTLY
// This file is automatically generated by the LocaleRouterCopy.
// If you need to make changes, edit the corresponding file in the app/[locale] folder.
// Then run `pnpm dev` to regenerate this file.
"use client";

import { Header } from "containers/Header/Header";
import SignInPage from "containers/SignInPage";
import { AuthProvider } from "contexts/AuthProvider";

export default function Page() {
    return (
        <AuthProvider>
            <SignInPage header={<Header />} fullWidth />
        </AuthProvider>
    );
}
